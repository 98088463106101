import { PaginationConst } from '../utils/constants/AppConstant';
import { PageItemCount } from '../utils/lib/PaginationUtil';
import HttpClient from './HttpClient';
import { RELEASE_SERVICE_HOST, RELEASES_API_PREFIX } from './RestConstants';

/**
 * @description Get release based on the request criteria
 * @param {String} productId
 * @returns {Promise}
 */
export async function getMetricsByPagination(projectId: string, pageNumber: number = 0, filterObj: Object = {}) {
  return HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/metrics?skip=${PageItemCount(pageNumber)}&size=${
      PaginationConst.PAGE_SIZE
    }`,
    params: filterObj,
  });
}


export async function getMetricInsightsByPagination(projectId: string,metricId:string, pageNumber: number = 0, filterObj: Object = {}) {
  return HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/metrics/${metricId}/metric-insights?skip=${PageItemCount(pageNumber)}&size=${
      PaginationConst.PAGE_SIZE
    }`,
    params: filterObj,
  });
}


/**
 * @description Create new release
 * @param {Object} releaseObj
 * @returns {Promise}
 */
export async function createMetrics(projectId: string, releaseObj: Object = {}) {
  const response = await HttpClient.POST({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/metrics`,
    data: releaseObj,
  });

  return response;
}

export async function createMetricInsights(projectId: string,metricId:string, releaseObj: Object = {}) {
  const response = await HttpClient.POST({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/metrics/${metricId}`,
    data: releaseObj,
  });

  return response;
}
