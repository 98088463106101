import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { getReleaseTopicsPagination } from '../../../services/ReleaseTopics';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { CREATE_RELEASE_TOPICS, parseURI } from '../../../utils/parser/URIFormatter';
import { ReleaseTopicsList } from '../../../utils/interface/ReleaseTopicInterface';
import { PageCount } from '../../../utils/lib/PaginationUtil';
import {
  EmptyResult,
  extractResult,
  isResultNotEmpty,
  TableComponentBody,
  TableComponentHeader,
} from '../../shared-components/table/TableComponent';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS, PaginationConst } from '../../../utils/constants/AppConstant';
import { ParseToDate } from '../../../utils/parser/DateParser';
import { DaysLeft } from '../../shared-components/days-left/DaysLeft';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import { TableContentLebel, TableHeaderLabel } from '../../../styles/fonts/FontStyle';
import { ReleaseTopicStatus } from '../../shared-components/status/ReleaseTopicStatus';
import Filter from '../../shared-components/filter/Filter';
import { RELEASE_TOPIC_PARAMS } from '../../shared-components/filter/config/FilterParams';
import { Link } from 'react-router-dom';

export function ListReleaseTopics(props = {}): React.ReactElement {
  const { projectId = '' } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [releaseTopic, setReleaseTopics] = useState<ReleaseTopicsList>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const releaseTopicsAPI = async (pageNumber: number = 1, filter: Object = {}) => {
    setOpen(true);
    try {
      //const releaseTopics: any = await getReleaseTopicsPagination(projectId, pageNumber, {});
      getReleaseTopicsPagination(projectId, pageNumber, PaginationConst.PAGE_SIZE , filter)
        .then((releaseTopics) => {
          console.log('Result ', releaseTopics);
          setReleaseTopics(releaseTopics.data);
          setOpen(false);
        })
        .catch((err) => {
          console.log('Result ', err);
          setOpen(false);
        });
    } catch (err) {
      console.log('Failure Catched', err);
      enqueueSnackbar('Error in creating release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.WARN,
      });
      setOpen(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log('Changed Value  ', value);
    setPage(value);
    releaseTopicsAPI(value);
  };

  useEffect(() => {
    console.log(`Values ${searchParams}`);
    releaseTopicsAPI(1, searchParams);
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const tableContent = (id: string, name: string, description: string, plannedDate: Date, status: string) => {
    return (
      <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell sx={TableContentLebel} component="th" scope="row">
          <Tooltip title={description}>
            <Typography> {name}</Typography>
          </Tooltip>
        </TableCell>
        <TableCell sx={TableContentLebel} component="th" scope="row">
          {ParseToDate(plannedDate)}
        </TableCell>
        <TableCell component="th" scope="row">
          <DaysLeft date={plannedDate} status={status}></DaysLeft>
        </TableCell>
        <TableCell sx={TableContentLebel} component="th" scope="row">
          <ReleaseTopicStatus status={status}></ReleaseTopicStatus>
        </TableCell>
        <TableCell component="th" scope="row">
          <Button sx={{ marginRight: 1 }} variant="outlined" href={`release-topics/${id}/details`}>
            Board
          </Button>
          <Button variant="outlined" href={`release-topics/${id}/edit`}>
            Edit
          </Button>
        </TableCell>
      </TableRow>
    );
  };
  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        <MainContentHeader
          label="Release Topics"
          buttonHref={parseURI(CREATE_RELEASE_TOPICS, projectId)}
          buttonLabel="Create Release Topic"
        />
        <Filter
          params={RELEASE_TOPIC_PARAMS}
          callback={(data: any) => {
            console.log('Data', data);
            // navigate(`${data}`);
            window.location.href = `${data}`;
          }}
          // clearFilter={navigate(``, { replace: true })}
        />
        <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableComponentHeader>
                <TableRow>
                  <TableCell sx={TableHeaderLabel}>
                    Topic Name
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <GridMoreVertIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell sx={TableHeaderLabel} title="YYYY/MM/DD">
                    Due Date
                  </TableCell>
                  <TableCell sx={TableHeaderLabel}>Days Left</TableCell>
                  <TableCell sx={TableHeaderLabel}>Status</TableCell>
                  <TableCell sx={TableHeaderLabel}>Actions</TableCell>
                </TableRow>
              </TableComponentHeader>
              <TableComponentBody>
                {isResultNotEmpty(releaseTopic) ? (
                  extractResult(releaseTopic).map((row: any, index: number) =>
                    tableContent(row._id, row.name, row.description, row.plannedReleaseDate, row.status)
                  )
                ) : (
                  <EmptyResult />
                )}
              </TableComponentBody>
            </Table>
          </TableContainer>
          <Pagination
            count={PageCount(releaseTopic?.totalCount)}
            sx={{ padding: 2, marginLeft: '30%' }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
