import { Typography } from "@mui/material";
import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';

export default function NotFound(): React.ReactElement {
  return (
    <Typography fontSize={30} sx ={{p:2}}>
      <BrowserNotSupportedIcon />
      <p>Not Found</p>
    </Typography>
  );
}
