import HttpClient from './HttpClient';
import { RELEASE_SERVICE_HOST, RELEASES_API_PREFIX } from './RestConstants';

/**
 * @description Get release based on the request criteria
 * @param {String} productId
 * @returns {Promise}
 */
export async function getReferences(projectId:string,releaseTopicId:string, filterObj: Object = {}) {
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/references/release-topics/${releaseTopicId}`,
    params: filterObj,
  });

  return response.data || {};
}

/**
 * @description Create new release
 * @param {Object} releaseObj
 * @returns {Promise}
 */
export async function createReferences(projectId: string, releaseObj: Object = {}) {
  const response = await HttpClient.POST({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/references`,
    data: releaseObj,
  });
  return response.data || {};
}