export const TYPE_TEXT_FIELD = "textfield";
export const TYPE_DROP_DOWN = "dropdown";
export const RELEASE_TOPIC_PARAMS:Array<FilterParams> = [
  {
    key: 'name',
    label: 'Name',
    type:TYPE_TEXT_FIELD
  },
  {
    key: 'status',
    enums: ['created', 'freezed', 'breached', 'recalled', 'completed', 'grooming'],
    label: 'Status',
    type:TYPE_DROP_DOWN
  },  {
    key: 'asc',
    enums: ["true", "false"],
    label: 'Order By Asc',
    type:TYPE_DROP_DOWN
  },
];

export type FilterParams = {
    key: string,
    enums?: Array<any>,
    label: string,
    type: string
}