import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getReleases } from '../../../services/Release';

import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import {
  COMMIT_RELEASE,
  CREATE_RELEASE,
  EDIT_RELEASE,
  GROOM_PRODUCT,
  parseURI,
  RELEASE_MANIFEST,
} from '../../../utils/parser/URIFormatter';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { TableHeaderLabel } from '../../../styles/fonts/FontStyle';
import { ReleaseCommitmentsList } from '../../../utils/interface/ReleaseCommitmentInterface';
import { EmptyResult, extractResult, isResultNotEmpty } from '../../shared-components/table/TableComponent';
import { PageCount } from '../../../utils/lib/PaginationUtil';
import { ParseToDate } from '../../../utils/parser/DateParser';
import { DaysLeft } from '../../shared-components/days-left/DaysLeft';
export function ListReleases(props = {}): React.ReactElement {
  const { projectId = '', productId = '' } = useParams();
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [response, setResponse] = useState<ReleaseCommitmentsList>();
  const getReleaseAPI = async (pageNumber: number = 1) => {
    setOpen(true);
    const result = await getReleases(projectId, productId, pageNumber, {});
    setResponse(result);
    setOpen(false);
  };
  useEffect(() => {
    getReleaseAPI();
  }, []);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openButton = Boolean(anchorEl);
  const handleClickDotBtn = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDotBtn = () => {
    setAnchorEl(null);
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log('Changed Value  ', value);
    setPage(value);
    getReleaseAPI(value);
  };
  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        <MainContentHeader
          label="Release Commitments"
          buttonHref={parseURI(CREATE_RELEASE, projectId, productId)}
          buttonLabel="Create"
        />

        <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={TableHeaderLabel}>Name</TableCell>
                  <TableCell sx={TableHeaderLabel}>Status</TableCell>
                  <TableCell sx={TableHeaderLabel}>Days Left</TableCell>
                  <TableCell sx={TableHeaderLabel}>Planned Release Date</TableCell>
                  <TableCell sx={TableHeaderLabel}>Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isResultNotEmpty(response) ? (
                  extractResult(response).map((row: any, index: number) => (
                    <TableRow key={row?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {row?.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.status}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <DaysLeft date={row?.plannedReleaseDate} status={row.status}></DaysLeft>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {ParseToDate(row?.plannedReleaseDate)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={openButton ? 'long-menu' : undefined}
                          aria-expanded={openButton ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={handleClickDotBtn}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={openButton}
                          onClose={handleCloseDotBtn}
                        >
                          <MenuItem key="commit">
                            <Button size="small" href={parseURI(COMMIT_RELEASE, projectId, row.productId, row._id)}>
                              Commit
                            </Button>
                          </MenuItem>
                          <MenuItem key="edit-release">
                            <Button size="small" href={parseURI(EDIT_RELEASE, projectId, row?.productId, row?._id)}>
                              Edit
                            </Button>
                          </MenuItem>
                          <MenuItem key="publish-release">
                            <Button size="small" href={parseURI(GROOM_PRODUCT, projectId, row?.productId, row?._id)}>
                              Publish
                            </Button>
                          </MenuItem>
                          <MenuItem key="insights-release">
                            <Button size="small" href={parseURI(RELEASE_MANIFEST, projectId, row?.productId, row?._id)}>
                              Insights
                            </Button>
                          </MenuItem>
                          {/* <MenuItem key="vote" >
                          <Button size="small" href={`/products/${params.productId}/release/${row?._id}/vote`}>Vote</Button>
                        </MenuItem> */}
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <EmptyResult />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={PageCount(response?.totalCount)}
            sx={{ padding: 2, marginLeft: '30%' }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
