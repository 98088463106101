//export const HOST = 'http://service.releasepilot.com';
export const HOST = (process.env.NODE_ENV==='production')?'http://service.releasepilot.com':'http://localhost';
export const RELEASES_SERVICE_PORT = 8080;
export const AUTH_SERVICE_PORT = 8081;
export const ARTIFICAL_ASSISTANCE_SERVICE_PORT = 8085;

export const RELEASE_SERVICE_HOST = (process.env.NODE_ENV==='production')?`${HOST}`:`${HOST}:${RELEASES_SERVICE_PORT}`;
export const AUTH_SERVICE_HOST = (process.env.NODE_ENV==='production')?`${HOST}`:`${HOST}:${AUTH_SERVICE_PORT}`;
export const ARTIFICAL_ASSISTANCE_SERVICE_HOST = (process.env.NODE_ENV==='production')?`${HOST}`:`${HOST}:${ARTIFICAL_ASSISTANCE_SERVICE_PORT}`;
export const AUTH_API_PREFIX = '/auth/v1';
export const RELEASES_API_PREFIX = '/release-manager/v1';
export const ARTIFICAL_ASSISTANCE_API_PREFIX = '/artificial-assistance/v1';
