import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Pagination, Paper, Table, TableCell, TableContainer, TableRow } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { addUserIntoGroup, getUsers } from '../../../services/Auth';
import { EmptyResult, TableComponentBody, TableComponentHeader } from '../../shared-components/table/TableComponent';
import { TableContentLebel, TableHeaderLabel } from '../../../styles/fonts/FontStyle';
import { PageCount } from '../../../utils/lib/PaginationUtil';
import { UsersListInterface } from '../../../utils/interface/UsersListInterface';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../utils/constants/AppConstant';
import { CREATED_SUCCESS } from '../../../translations/properties_dev';

export default function AddUser(props: Object): React.ReactElement {
  const { usergroupid = '' } = useParams();
  const [tableData, setTableData] = useState<UsersListInterface>();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const getUsersList = async (pageNumber: number = 1) => {
    setOpen(true);
    const result = await getUsers();

    setTableData(result.data);

    setOpen(false);
  };
  useEffect(() => {
    getUsersList();
  }, [setTableData]);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log('Changed Value  ', value);
    setPage(value);
    getUsersList(value);
  };
  const addUser = async (userId: string) => {
    setOpen(true);
    try {
      const result = await addUserIntoGroup(usergroupid, { userId: userId });
      enqueueSnackbar(CREATED_SUCCESS, {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.SUCCESS,
      });
      setOpen(false);
    } catch (err) {
      enqueueSnackbar('Error in creating release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.WARN,
      });
      setOpen(false);
    }
  };
  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={2}>
        <MainContentHeader label="Users" />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableComponentHeader>
              <TableRow>
                <TableCell sx={TableHeaderLabel}>Firstname</TableCell>
                <TableCell sx={TableHeaderLabel}>Lastname</TableCell>
                <TableCell sx={TableHeaderLabel}>Email</TableCell>
                <TableCell sx={TableHeaderLabel}>Actions</TableCell>
              </TableRow>
            </TableComponentHeader>
            <TableComponentBody>
              {tableData && tableData ? (
                tableData.result.map((row: any, index: number) => (
                  <TableRow key={row?._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={TableContentLebel} component="th" scope="row">
                      {row?.firstName}
                    </TableCell>
                    <TableCell sx={TableContentLebel} component="th" scope="row">
                      {row?.lastName}
                    </TableCell>
                    <TableCell sx={TableContentLebel} component="th" scope="row">
                      {row?.email}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Button sx={{ marginRight: 1 }} onClick={(e) => addUser(row._id)} variant="outlined">
                        <PersonAddIcon fontSize="medium" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <EmptyResult />
              )}
            </TableComponentBody>
          </Table>
        </TableContainer>
        <Pagination
          count={PageCount(tableData?.totalCount)}
          sx={{ padding: 2, marginLeft: '30%' }}
          onChange={handleChange}
        />
      </Grid>
    </Container>
  );
}
