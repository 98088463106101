import { styled } from "@mui/material";

export const WriterGrid = styled('div')({
    color: 'darkslategray',
    padding:'10px',
    height:'500px',
    overflow:"auto",
    'border-radius':'5px'
});


export const ActionButtonGrid = styled('div')({
//    'box-shadow':"0px 0px 2px #000",
   right:'0px'
});


export const ReleaseTopicHeader = styled('p')({
    fontSize:'25px',
    marginBottom:'10px'
 });