import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';

import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { TableContentLebel, TableHeaderLabel } from '../../../styles/fonts/FontStyle';
import { getValidationPlans } from '../../../services/ValidationPlans';
import {  ValidationPlanListInterface } from '../../../utils/interface/ValidationPlans';
import { CREATE_VALIDATION_PLAN, parseURI, UPDATE_VALIDATION_PLANS } from '../../../utils/parser/URIFormatter';
import { EmptyResult, extractResult, isResultNotEmpty } from '../../shared-components/table/TableComponent';
import { PageCount } from '../../../utils/lib/PaginationUtil';
import { ParseToDate } from '../../../utils/parser/DateParser';
export function ListValidationPlan(props = {}): React.ReactElement {
  const {projectId=''} = useParams();
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [validationPlansList,setValidationPlansList] = useState<ValidationPlanListInterface>();
 
  const releaseValidationPlansAPI = async (pageNumber: number = 1) => {
    setOpen(true);
    const releaseTopics: any = await getValidationPlans(projectId,pageNumber);
    setValidationPlansList(releaseTopics.data);
    setOpen(false);
  };

  useEffect(() => {
    releaseValidationPlansAPI();
  }, []);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log('Changed Value  ', value);
    setPage(value);
    releaseValidationPlansAPI(value);
  };
  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        {/* <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <IHeaderLabel><AccountTreeIcon sx={SideBarIconColor} />  Release Topics</IHeaderLabel>
            <Divider />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Box textAlign={'right'} mr={4} mb={2}>
              <Button variant="contained" href={`/release-topics/create`}>
                <AddIcon />
                Create Release Topic
              </Button>
            </Box>
          </Grid>
        </Grid> */}
        <MainContentHeader label="Validation Plans" buttonHref={parseURI(CREATE_VALIDATION_PLAN,projectId)} buttonLabel='Create Validation Plan'/>
        <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={TableHeaderLabel} >Plan Name</TableCell>
                  <TableCell sx={TableHeaderLabel}>Start Date</TableCell>
                  <TableCell sx={TableHeaderLabel}>End Date</TableCell>
                  <TableCell sx={TableHeaderLabel}>Actions</TableCell>
                  {/* <TableCell align="right">Calories</TableCell> */}
                  {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
              <TableCell align="right">Carbs&nbsp;(g)</TableCell>
              <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {isResultNotEmpty(validationPlansList)?extractResult(validationPlansList).map((row: any, index: number) => (
                  <TableRow key={row?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={TableContentLebel} component="th" scope="row">
                      {row?.name}
                    </TableCell>
                    <TableCell sx={TableContentLebel} component="th" scope="row">
                      {ParseToDate(row?.startDate)}
                    </TableCell>
                    <TableCell sx={TableContentLebel} component="th" scope="row">
                      {ParseToDate(row?.endDate)}
                    </TableCell>
                    <TableCell component="th" scope="row">                      
                      <Button variant="outlined" href={parseURI(UPDATE_VALIDATION_PLANS,projectId,row?._id)}>
                        Edit
                      </Button>
                      <Button sx={{ marginRight: 1 }} variant="outlined" href={`validation-plans/${row?._id}/release-topics`}>
                        Release Topics
                      </Button>
                    </TableCell>
                  </TableRow>
                )):<EmptyResult/>}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={PageCount(validationPlansList?.totalCount)}
            sx={{ padding: 2, marginLeft: '30%' }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
