import { Box, TextField, Typography, CssBaseline, List, Chip, Divider, Modal, Button, Paper } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { GridSaveAltIcon } from '@mui/x-data-grid';
import { createConversations } from '../../../services/Conversations';
import { EmptyResultPaper } from '../../shared-components/table/TableComponent';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { NOTIFICATION_VARIANTS } from '../../../utils/constants/AppConstant';

import { ReferencesInterface, ReferencesListInterface } from '../../../utils/interface/ReferencesListInterface';
import { createReferences, getReferences } from '../../../services/References';
import { LinkIcon } from './styles/DetailsStyle';
type Props = {};
const style = {
  position: 'absolute' as 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
export function ReleaseTopicReferences(props: Props): React.ReactElement {
  const [reference, setReferences] = useState<Array<ReferencesInterface>>([]);
  const { projectId = '', topicId = '' } = useParams();
  const [modelOpen, setModelOpen] = React.useState(false);
  const handleOpen = () => setModelOpen(true);
  const handleClose = () => setModelOpen(false);
  useEffect(() => {}, []);

  const [open, setOpen] = React.useState(false);

  const validationSchema = yup.object({
    message: yup.string().required('Please enter Message'),
  });
  interface Payload {
    releaseTopicId: string;
    type: string;
    links?: Array<string>;
    message: string;
  }
  const onsubmit = async () => {
    console.log(`Rest API Call Save Release`);
    setOpen(true);
    var payload: Payload = formik.values;
    payload.links = Array(formik.values.link);
    payload.releaseTopicId = topicId;
    try {
      const result = await createReferences(projectId, payload);
      if (result) {
        enqueueSnackbar('Created Successfully', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        });
        getReferencesAPI();

        setOpen(false);
        setModelOpen(false);
        formik.setValues({
          releaseTopicId: topicId,
          type: 'RELEASE_TOPIC',
          link: '',
          message: '',
        });
        //navigate(`/products/${productId}/release`);
      } else {
        enqueueSnackbar('Error in creating', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.WARN,
        });
        setOpen(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    validationSchema,
    initialValues: {
      releaseTopicId: '',
      type: 'RELEASE_TOPIC',
      link: '',
      message: '',
    },
    onSubmit: onsubmit,
  });

  const getReferencesAPI = async () => {
    const references: ReferencesListInterface = await getReferences(projectId, topicId);
    if (references && references.result) {
      setReferences(references.result);
    }
  };
  useEffect(() => {
    setOpen(true);
    getReferencesAPI();
    setOpen(false);
  }, []);

  const loadLinks = () => {
    return (
      <List sx={{ width: '60%', minHeight: 600, bgcolor: 'background.paper' }}>
        {reference ? (
          reference.map((value: ReferencesInterface) => (
            <Paper elevation={3} sx={{ marginTop: 1, borderRadius: 2 }}>
              <Box sx={{ p: 2 }}>
                <Typography color="text.secondary" variant="h6">
                  {value.message}2
                </Typography>
                <Divider />
                <Box sx={{ pt: 2 }}>
                  {value.links.map((value) => (                  
                    <LinkIcon><a target="_blank" rel="noreferrer" href={value}>{value}</a></LinkIcon>
                  ))}
                </Box>
              </Box>
            </Paper>
          ))
        ) : (
          <EmptyResultPaper />
        )}
      </List>
    );
  };

  return (
    <Box sx={{ pb: 7 }}>
      <CssBaseline />
      <Button variant="outlined" onClick={handleOpen}>
        NEW
      </Button>
      {loadLinks()}

      <Modal
        open={modelOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: 2 }}
      >
        <Box sx={style}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              label="Message"
              variant="outlined"
              sx={{ width: '95%', margin: 1 }}
              id="message"
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />

            <TextField
              id="link"
              name="link"
              label="Link"
              variant="outlined"
              sx={{ width: '95%', margin: 1 }}
              value={formik.values.link}
              onChange={formik.handleChange}
              error={formik.touched.link && Boolean(formik.errors.link)}
              helperText={formik.touched.link && formik.errors.link}
            />

            <LoadingButton
              sx={{ padding: 1, width: '18%', marginLeft: '40%' }}
              loading={false}
              loadingPosition="start"
              startIcon={<GridSaveAltIcon />}
              variant="outlined"
              type="submit"
            >
              POST
            </LoadingButton>
          </form>
        </Box>
      </Modal>
    </Box>
  );
}
