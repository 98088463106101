import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  Button,
  Container,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { Input as BaseInput, InputProps } from '@mui/base/Input';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../utils/constants/AppConstant';
import React from 'react';
import { createRoadMap } from '../../../services/RoadMap';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { useParams } from 'react-router-dom';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { parseURI, ROADMAP_HOME } from '../../../utils/parser/URIFormatter';

export default function CreateRoadMap() {
  const {projectId=''} = useParams();
  const [open, setOpen] = React.useState(false);
  const [priority, setPriority] = React.useState('');
  const handleChange = (event: SelectChangeEvent) => {
    setPriority(event.target.value as string);
  };
  const validationSchema = yup.object({
    // name: yup.string().required('Please enter name'),
    // description: yup.string().required('Please enter description'),
    // priority: yup.string().required('Please enter version'),
  });
  const submitRoadMap = async () => {
    console.log(`Rest API Call Save Release`);
    setOpen(true);
    try {
      const result = await createRoadMap(projectId,formik.values);
      console.log(result)
      if (result.status && result.data) {
        enqueueSnackbar('Created Successfully', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        });        
        setOpen(false);
      } else {
        enqueueSnackbar('Error in creating release', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.WARN,
        });
        setOpen(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: '',
      description: '',
      startDate: '',
      endDate: '',
      priority: '',
    },
    onSubmit: submitRoadMap,
  });

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>      
      <BackdropComponent visible={open} />      
      <Grid container spacing={2}>
        <MainContentHeader 
            label="Create Road Map"        
            buttonHref={parseURI(ROADMAP_HOME, projectId)}
            buttonLabel="View"
         />
        <Grid item xs={8} md={8} lg={8}>
          
            {/* 
              List of attributes
              1. Release Topic name
              2. Description
              3. Planned Release date
              4. Priority (high,medium,low)
              5. Reference ()
              6. Release Demo (demo1)
              7. Source (person / document)
              8. Topic Owners (person-1,person-2,person-3)
            */}
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 1, padding: 4, width: '100%' }}>
                <Grid item xs={12} sx={{}}>
                  <TextField
                    required
                    fullWidth
                    id="name"
                    name="name"
                    margin="normal"
                    label="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    // error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <TextField
                    required
                    fullWidth
                    id="description"
                    name="description"
                    margin="normal"
                    label="Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    // error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                  />
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Start Date"
                      name="startDate"
                      value={formik.values.startDate}
                      onChange={(value: any) => {
                        formik.setFieldValue('startDate', Date.parse(value));
                      }}
                      slotProps={{
                        textField: {
                          id: 'startDate',
                          variant: 'outlined',
                          fullWidth: true,
                          sx: { marginTop: 2 },
                          error: formik.touched.startDate && Boolean(formik.errors.startDate),
                          helperText: formik.touched.startDate && formik.errors.startDate,
                        },
                      }}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="End Date"
                      name="endDate"
                      value={formik.values.endDate}
                      onChange={(value: any) => {
                        formik.setFieldValue('endDate', Date.parse(value));
                      }}
                      slotProps={{
                        textField: {
                          id: 'endDate',
                          variant: 'outlined',
                          fullWidth: true,
                          sx: { marginTop: 2 },
                          error: formik.touched.endDate && Boolean(formik.errors.endDate),
                          helperText: formik.touched.endDate && formik.errors.endDate,
                        },
                      }}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </Grid>

                <br></br>
                <Grid item xs={12} sx={{}}>
                  <FormControl fullWidth>
                    <InputLabel id="priority-select-label">Priority</InputLabel>
                    <Select
                      labelId="priority-select-label"
                      id="priority"
                      name="priority"
                      value={priority}
                      label="Priority"
                      sx={{ textAlign: 'left' }}
                      onChange={handleChange}
                    >
                      <MenuItem value={'High'}>High</MenuItem>
                      <MenuItem value={'Medium'}>Medium</MenuItem>
                      <MenuItem value={'Low'}>Low</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <br />
                <Grid container xs={12} sx={{ marginLeft: 6, minWidth: '100%', textAlign: 'center' }}>
                  <center>
                    <Button sx={{ marginX: 2 }} color="primary" variant="contained" type="submit">
                      Save
                    </Button>

                    <Button sx={{ marginX: 2 }} color="inherit" variant="contained" type="reset">
                      Clear
                    </Button>
                  </center>
                </Grid>
              </Box>
            </form>
        </Grid>
      </Grid>
    </Container>
  );
}
