import { Autocomplete, Avatar, Chip, Grid, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { User, UsersListInterface } from '../../../utils/interface/UsersListInterface';

import FaceIcon from '@mui/icons-material/Face';
interface Props {
  open?: boolean;
  ownerUser: string;
}
export function UserPreview(props: Props): React.ReactElement {
 
  const showOwnerUser = () => {
    return <Chip sx={{fontSize:20}} avatar={<Avatar>M</Avatar>} label={props.ownerUser}  variant="outlined"  />;
  };
  return <>{showOwnerUser()}</>;
}
