import { TableHead, TableBody, TableRow, Typography, TableCell, Paper, Alert } from '@mui/material';
import React from 'react';
import { TableContentLebel, TableNotFound } from '../../../styles/fonts/FontStyle';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
type Props = {
  tableBody?: any;
  children?: string | JSX.Element | JSX.Element[] | undefined;
};
export function TableComponentHeader(props: Props): React.ReactElement {
  return <TableHead>{props.children}</TableHead>;
}

export function TableComponentBody(props: Props): React.ReactElement {
  return <TableBody>{props.children}</TableBody>;
}

export function EmptyResult(props: Props): React.ReactElement {
  return (
    <>
      <TableRow sx={{ textAlign: 'center', margin: 2 }}>
        <TableCell colSpan={2} sx={TableContentLebel} component="th" scope="row">
          <Typography sx={TableNotFound} variant="body2" component="div">
            No Record Found
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
}

export function isResultNotEmpty(state:any):boolean{
  return (state && state.result && state.result.length>0);
}


export function extractResult(state:any):any{
  return (state && state.result && state.result.length>0)?state.result:[];
}

export function EmptyResultPaper(props: Props): React.ReactElement {
  return (
    <>
      <Alert variant="filled" severity="info">
        <Typography sx={{ textAlign: 'center' }} variant="body1" component="div">
          Empty
        </Typography>
      </Alert>
    </>
  );
}
