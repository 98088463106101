import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { getProjects, getProjectsById } from '../../../services/Projects';
import { Projects } from '../../../interface/Projects';
import { useParams } from 'react-router-dom';
import {
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import { blue } from '@mui/material/colors';
import { BackdropSkeletonComponent } from '../backdrop/Backdrop';
import NotFound from '../../misc/NotFound';
interface Props {
  selectorComp?: boolean;
}
export default function ProjectSelector(props: Props) {
  const { selectorComp = false } = props;
  const [selectedProject, setSelectedProject] = React.useState<Projects>();
  const [projects, setProjects] = React.useState<Array<Projects>>([]);
  const [dialogueOpen, setDialogueOpen] = React.useState<boolean>(selectorComp);
  const [open, setOpen] = React.useState(false);
  const { projectId } = useParams();
  const handleClickOpen = () => {
    setDialogueOpen(true);
  };
  const fetchProjectsAPI = async () => {
    const projectResult = await getProjects();
    setProjects(projectResult.data.result);
  };
  const fetchProjectsByIdAPI = async (projectId: string) => {
    setOpen(true);
    const projectResult = await getProjectsById(projectId);
    setSelectedProject(projectResult.data);
    setOpen(false);
  };
  useEffect(() => {
    fetchProjectsAPI();
    if (projectId) {
      fetchProjectsByIdAPI(projectId);
    } else {
      setDialogueOpen(true);
    }
  }, []);

  const dialogue = () => {
    return (
      <Dialog
        open={dialogueOpen}
        onClose={(e) => {
          if (!selectorComp) setDialogueOpen(false);
        }}
        sx={{ padding: 2 }}
      >
        <DialogTitle>Select Project</DialogTitle>
        <List sx={{ pt: 0 }}>
          {projects && projects.length > 0 ? (
            projects.map((project) => (
              <ListItem disableGutters key={project._id}>
                <ListItemButton href={`/${project._id}/dashboard`}>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={project.name} />
                </ListItemButton>
              </ListItem>
            ))
          ) : (
            <>
              <BackdropSkeletonComponent loading={open} />
              <NotFound></NotFound>
            </>
          )}
          <ListItem disableGutters>
            <ListItemButton autoFocus href="/projects/new">
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Create Project" />
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
    );
  };
  return (
    <div>
      <FormControl fullWidth>
        {!selectorComp ? (
          <Button variant="outlined" sx={{ textAlign: 'center' }} onClick={handleClickOpen}>
            {selectedProject ? `${selectedProject.name} ( Switch )` : 'Select Project'}
          </Button>
        ) : (
          <></>
        )}
        {dialogue()}
      </FormControl>
    </div>
  );
}
