import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
  IconButton,
  createFilterOptions,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { callback, getReleaseById, getReleases, publishRelease } from '../../../services/Release';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../utils/constants/AppConstant';
import HeaderCard from '../../shared-components/header-card/HeaderCard';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import { Formik, Form } from 'formik';
import { useFormik } from 'formik';
import { getUserGroupList } from '../../../services/Auth';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
interface Release {
  _id: string;
  name: string;
  type: string;
  description: string;
  plannedReleaseDate: string;
  linkedGroupId: string;
  version: string;
  productId: string;
  status: String;
}

interface UserGroup {
  _id: string;
  name: string;
  description: string;
}
export default function ManageRelease() {
  const [account, setAccount] = useState();
  const [userGroupIds, setUserGroupIds] = useState('');
  const [userGroup, setUserGroup] = useState<Array<UserGroup>>([]);
  const [releaseInfo, setReleaseInfo] = useState<Release>();
  const { productId = '', releaseId = '', projectId = '' } = useParams();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: UserGroup) => option.name,
  });

  const getRelaseByReleaseId = async () => {
    try {
      const releaseData = await getReleaseById(projectId, productId,releaseId);

      if (!releaseData) {
        throw new Error('Release not found');
      }
      setReleaseInfo(releaseData.data);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error occured while fething the release information', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.ERROR,
      });
    }
  };

  const getUserGroups = async () => {
    const userGroupResult = await getUserGroupList(productId);
    setUserGroup(userGroupResult.data.result);
  };

  const publishReleaseAPI = async () => {
    setOpen(true);
    const save = await publishRelease(projectId, productId, releaseId, formik.values);
    if (save) {
      getRelaseByReleaseId();
      setOpen(false);
    } else {
      setOpen(false);
    }
  };

  const callBackRelease = async () => {
    setOpen(true);
    const save = await callback(projectId, productId, releaseId, formik.values);
    if (save) {
      getRelaseByReleaseId();
      setOpen(false);
    } else {
      setOpen(false);
    }
  };
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    if (releaseId) getRelaseByReleaseId();
    getUserGroups();
  }, [setAccount, productId]);
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const formik = useFormik({
    initialValues: {
      usergroupIds: '',
      documentationLink: '',
    },
    onSubmit: publishReleaseAPI,
  });
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const releaseCallBack = () => {
    return (
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            Please add the requried product groups using below options to inform post publishing the release.
          </Alert>

          <Formik initialValues={{}} onSubmit={callBackRelease}>
            <Form>
              <Box sx={{ mt: 1, padding: 4, width: '100%' }}>
                <Grid item xs={5} sx={{}}>
                  <Typography variant="body2" color="text.secondary">
                    <Checkbox checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                    Please accept the terms and conditions
                  </Typography>
                </Grid>
                <Divider></Divider>
                <Grid item sx={{ padding: 4 }}>
                  <center>
                    <Button sx={{ marginX: 2 }} color="primary" variant="contained" disabled={!checked} type="submit">
                      Call
                    </Button>
                  </center>
                </Grid>
              </Box>
            </Form>
          </Formik>
        </Paper>
      </Grid>
    );
  };

  const publishComponent = () => {
    return (
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            Please add the requried product groups using below options to inform post publishing the release.
          </Alert>

          <Formik
            initialValues={{
              usergroupIds: '',
              documentationLink: '',
            }}
            onSubmit={publishReleaseAPI}
          >
            <Form>
              <Box sx={{ mt: 1, padding: 4, width: '100%' }}>
                <Grid item xs={5} sx={{}}>
                  <Autocomplete
                    id="filter-demo"
                    options={userGroup}
                    getOptionLabel={(option) => option.name}
                    filterOptions={filterOptions}
                    getOptionKey={(options) => options._id}
                    renderInput={(params) => <TextField {...params} label="Usergroups" />}
                    // inputValue={userGroupIds}
                    onChange={(event, value) => {
                      formik.setFieldValue('usergroupIds', value?._id);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setUserGroupIds(newInputValue);
                    }}
                  />
                </Grid>
                <Grid item xs={5} sx={{}}>
                  <TextField
                    required
                    fullWidth
                    id="documentationLink"
                    name="documentationLink"
                    margin="normal"
                    label="Documentation link"
                    value={formik.values.documentationLink}
                    onChange={formik.handleChange}
                    error={formik.touched.documentationLink && Boolean(formik.errors.documentationLink)}
                    helperText={formik.touched.documentationLink && formik.errors.documentationLink}
                  />
                </Grid>
                <Grid item xs={5} sx={{}}>
                  <Typography variant="body2" color="text.secondary">
                    <Checkbox checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                    Please accept the terms and conditions
                  </Typography>
                </Grid>
                <Divider></Divider>
                <Grid item sx={{ padding: 4 }}>
                  <center>
                    <Button sx={{ marginX: 2 }} color="primary" variant="contained" disabled={!checked} type="submit">
                      Publish
                    </Button>
                    <Button sx={{ marginX: 2 }} color="inherit" variant="contained" type="submit">
                      Clear
                    </Button>
                  </center>
                </Grid>
              </Box>
            </Form>
          </Formik>
        </Paper>
      </Grid>
    );
  };
  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{}}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message="Note archived" action={action} />
      <CssBaseline />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <HeaderCard title={releaseInfo?.name} description={releaseInfo?.description} />
        </Grid>
        {releaseInfo?.status !== 'freezed' ? publishComponent() : releaseCallBack()}
      </Grid>
    </Container>
  );
}
