import { Chip } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface Props {
  status: string;
}
export function ReleaseTopicStatus(props: Props): React.ReactElement {
  return (
    <>
      <Chip sx={{fontSize:20}} label={props.status} color="primary" variant="outlined" />
    </>
  );
}
