import { styled, Tooltip } from '@mui/material';
import { CalculateFromToday } from '../../../utils/parser/DateParser';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
interface Props {
  children?: React.ReactNode;
  date: Date | undefined;
  status?: string;
}
const GridSecondaryItemName = styled('span')({
  fontSize: '15px',
  padding: '5px',
});
const parseInterval = (days: Date | undefined): number => {
  if (!days) return 0;
  const dates: number = CalculateFromToday(days);
  return dates;
};

export function DaysLeft(props: Props) {
  const { date, status } = props;

  const decideTheme = (dates: number) => {
    let design: any = {
      color: '#000',
      borderRadius: 2,
      fontSize: '35',
      fontWeight: 'bold',
      fontFamily: 'Gill Sans, sans-serif',
    };
    if (dates > 0) {
      design.color = '#158a2e';
      design.border = '1px solid #158a2e';
      return design;
    } else {
      design.color = '#c90300';
      design.border = '1px solid #c90300';
      return design;
    }
  };
  return (
    <>
      {status && status !== 'completed' ? (
        <GridSecondaryItemName sx={decideTheme(parseInterval(date))}>{parseInterval(date)} Days</GridSecondaryItemName>
      ) : (
        <Tooltip title="All Good , Enjoy the day">
          <CheckCircleIcon sx={{ textAlign: 'center' }} fontSize="medium">
            None
          </CheckCircleIcon>
        </Tooltip>
      )}
    </>
  );
}

const isGreater = (dueDate: Date, plannedDate: Date) => {
  const dueDateLoc = new Date(dueDate);
  const plannedDateLoc = new Date(plannedDate);
  return dueDateLoc > plannedDateLoc;
};

export function DueDateTheme(dueDate: Date | undefined, plannedDate: Date | undefined, statusCheck: boolean | undefined) {
  let style = { boxShadow: '0px 0px 2px #000', border: '' };
  console.log(`${dueDate} - ${plannedDate} - ${statusCheck}`);
  if (!dueDate || !plannedDate) {
    return {};
  } else if (!statusCheck) {
    return style;
  } else if (isGreater(dueDate, plannedDate)) {
    style.border = '1px thin  #158a2e';
    style.boxShadow = '0px 0px 4px #158a2e';
    return style;
  } else {
    style.border = '1px thin  #c90300';
    style.boxShadow = '0px 0px 4px #c90300';
    return style;
  }
}
