import {  Box, Button, CircularProgress, Container, Divider, Grid, Modal, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import React from 'react';
import { ReleaseTopics } from '../../../utils/interface/ReleaseTopicInterface';
import { getReleaseTopicById, patchReleaseTopic } from '../../../services/ReleaseTopics';
import { BackdropComponent, BackdropSkeletonComponent } from '../../shared-components/backdrop/Backdrop';
import sanitizeHtml from 'sanitize-html';
import { ActionButtonGrid, WriterGrid } from './styles/TopicGroomingStyles';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { HeaderDetails } from '../details/HeaderDetails';
import { describeTopic } from '../../../services/ArtificialAssistance';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { EDIT_RELEASE_TOPIC, parseURI } from '../../../utils/parser/URIFormatter';
import {  AlertSuccess } from '../../shared-components/alert/Alert';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};
export default function TopicGrooming() {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [releaseTopic, setReleaseTopic] = useState<ReleaseTopics>();
  const { projectId = '', topicId = '' } = useParams();
  const [changeDetected, setChangeDetected] = React.useState<boolean>(false);
  const [openModel, setOpenModel] = React.useState(false);
  const [topic, setTopic] = React.useState(null);
  const [topicDescription, setTopicDescription] = React.useState();
  const handleOpen = () => setOpenModel(true);
  const handleClose = () => setOpenModel(false);
  const [AIResult,setAIResult] = React.useState(false);
  const handleChangeTopic = (evt: any) => {
    setTopic(evt.target.value);
  };
  //const onContentBlur = React.useCallback(evt => setContent(evt.currentTarget.innerHTML))
  const getTopicByIdAPI = async () => {
    if (topicId) {
      setOpen(true);
      const resultTopicDetails = await getReleaseTopicById(projectId, topicId);
      setReleaseTopic(resultTopicDetails.data);
      if (resultTopicDetails.data.content) {
        setValue(resultTopicDetails.data.content);
      }

      setOpen(false);
    }
  };
  useEffect(() => {
    getTopicByIdAPI();
  }, []);

  const patchReleaseTopicDoc = async () => {
    console.log('patchReleaseTopicDoc ', topicId);
    if (topicId != null) {
      setOpen(true);
      const payload = { content: value };
      await patchReleaseTopic(projectId, topicId, payload);
      setOpen(false);
    }
  };
  const onContentChange = React.useCallback((evt: any) => {
    setChangeDetected(true);
    const sanitizeConf = {
      allowedTags: ['b', 'i', 'a', 'p', 'br', 'div', 'hr', '&nbsp;'],
      allowedAttributes: { a: ['href'] },
    };

    setValue(sanitizeHtml(evt.currentTarget.innerHTML, sanitizeConf));
  }, []);

  const Editable = () => {
    return (
      <WriterGrid
        contentEditable
        onClick={(evt) => {
          onContentChange(evt);
        }}
        // onBlur={true}
        dangerouslySetInnerHTML={{ __html: value }}
      ></WriterGrid>
    );
  };

  const groomWithAI = async (topic: string | null) => {
    setOpen(true);
    const response = await describeTopic({ topic: topic });
    console.log(response);
    setAIResult(true);
    setTopicDescription(response.data.result.content);
    setOpen(false);
  };
  

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container sx={{ p: 2 }}>
        {releaseTopic ? (
          <HeaderDetails releaseTopic={releaseTopic} open={open} />
        ) : (
          <BackdropSkeletonComponent visible={true} />
        )}
        <Button variant="outlined" sx={{ mt: 2 }} href={parseURI(EDIT_RELEASE_TOPIC, projectId, topicId)}>
          {/* <DrawIcon sx={{ padding: '1px' }} fontSize="medium" /> Groom Topic */}
          <EditNoteIcon /> Edit
        </Button>
      </Grid>
      <Grid container sx={{ p: 2 }}>
        <Button onClick={handleOpen} variant="contained">
          AI Grooming
        </Button>
        <Modal open={openModel} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <AutoAwesomeIcon /> Groom with AI
            </Typography>
            <Box sx={{ padding: 3 }}>
              <TextField
                id="outlined-basic"
                label="Topic"
                sx={{ width: '100%' }}
                onChange={handleChangeTopic}
                variant="outlined"
              />
            </Box>
            <center>
              {!open ? (
                <>
                  {(AIResult)?<AlertSuccess message="Success, Click apply"/>:<></>}
                  <Button variant="contained" sx={{ m: 1 }} onClick={(evt) => groomWithAI(topic)}>
                    Groom
                  </Button>
                  
                  <Button
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={(evt) => {
                      if (topicDescription) {
                        handleClose();
                        setValue(topicDescription);
                      }
                    }}
                  >
                    Apply
                  </Button>

                  <Button sx={{ m: 1 }} variant="contained" onClick={handleClose}>
                    Close
                  </Button>
                </>
              ) : (
                <>
                  <CircularProgress />
                </>
              )}
            </center>
            {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {topicDescription}
            </Typography> */}
          </Box>
        </Modal>
      </Grid>
      {/* <TTSSpeaker text={value} /> */}
      <Divider />
      <Box>
        {/* <Dictaphone  /> */}
        {Editable()}
        {changeDetected ? (
          <ActionButtonGrid>
            <Button variant="contained" onClick={patchReleaseTopicDoc} sx={{ margin: 1 }}>
              save
            </Button>
            <Button variant="outlined" onClick={(evt) => setValue('')} sx={{ margin: 1 }}>
              Clear
            </Button>
          </ActionButtonGrid>
        ) : (
          <></>
        )}

        {/* {constributors()} */}
      </Box>
    </Container>
  );
}
