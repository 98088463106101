import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import Container from '@mui/material/Container';
import { Pagination, Paper, Table, TableCell, TableContainer, TableRow } from '@mui/material';
import { getUserGroupList } from '../../../services/Auth';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { EmptyResult, extractResult, isResultNotEmpty, TableComponentBody, TableComponentHeader } from '../../shared-components/table/TableComponent';
import { PageCount } from '../../../utils/lib/PaginationUtil';
import { UserGroupsListInterface } from '../../../utils/interface/UserGroupsListInterface';
import { TableContentLebel, TableHeaderLabel } from '../../../styles/fonts/FontStyle';
import { useParams } from 'react-router-dom';
import { ADD_USER_TO_GROUP, CREATE_USER_GROUP, parseURI, USERS_LIST } from '../../../utils/parser/URIFormatter';
export default function ListUserGroups() {
  const { projectId = '' } = useParams();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [usergroups, setUserGroups] = React.useState<UserGroupsListInterface>();

  const getUserGroupsListAPI = async (pageNumber: number = 1) => {
    setOpen(true);
    const groupList = await getUserGroupList(projectId, pageNumber);
    if (groupList) {
      setUserGroups(groupList.data);
    }
    setOpen(false);
  };
  useEffect(() => {
    getUserGroupsListAPI();
  }, []);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log('Changed Value  ', value);
    setPage(value);
    getUserGroupsListAPI(value);
  };
  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        <MainContentHeader
          label="Usergroups"
          buttonHref={parseURI(CREATE_USER_GROUP, projectId)}
          buttonLabel="Create Usergroup"
        />
        <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableComponentHeader>
                <TableRow>
                  <TableCell sx={TableHeaderLabel}>Groupname</TableCell>
                  <TableCell sx={TableHeaderLabel}>Actions</TableCell>
                </TableRow>
              </TableComponentHeader>
              <TableComponentBody>
                {isResultNotEmpty(usergroups)? (
                  extractResult(usergroups).map((row: any, index: number) => (
                    <TableRow key={row?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell  sx={TableContentLebel} component="th" scope="row">
                        {row?.name}
                      </TableCell>
                      <TableCell sx={TableContentLebel}  component="th" scope="row">
                        <Button variant="outlined" sx={{ margin: 1 }} href={parseURI(ADD_USER_TO_GROUP, projectId, row._id)}>
                          Add User
                        </Button>
                        <Button variant="outlined" href={parseURI(USERS_LIST, projectId, row._id)}>
                          Users
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <EmptyResult />
                )}
              </TableComponentBody>
            </Table>
          </TableContainer>
          <Pagination
            count={PageCount(usergroups?.totalCount)}
            sx={{ padding: 2, marginLeft: '30%' }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
