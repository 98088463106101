import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { METRIC_INSIGHT_NEW, parseURI } from '../../../utils/parser/URIFormatter';
import { PageCount } from '../../../utils/lib/PaginationUtil';
import { EmptyResult, extractResult, isResultNotEmpty, TableComponentBody, TableComponentHeader } from '../../shared-components/table/TableComponent';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../utils/constants/AppConstant';
import { ParseToDate } from '../../../utils/parser/DateParser';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import { TableContentLebel, TableHeaderLabel } from '../../../styles/fonts/FontStyle';
import { getMetricInsightsByPagination } from '../../../services/Metrics';
import { MetricInsightsList } from '../../../utils/interface/MetricsListInterface';

export function ListMetricInsights(props = {}): React.ReactElement {
  const { projectId = '', metricId = '' } = useParams();
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [metricInsights, setMetricInsights] = useState<MetricInsightsList>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const releaseTopicsAPI = async (pageNumber: number = 1) => {
    setOpen(true);
    try {
      //const releaseTopics: any = await getReleaseTopicsPagination(projectId, pageNumber, {});
      getMetricInsightsByPagination(projectId, metricId, pageNumber, {})
        .then((releaseTopics) => {
          console.log('Result ', releaseTopics);
          setMetricInsights(releaseTopics.data);
          setOpen(false);
        })
        .catch((err) => {
          console.log('Result ', err);
          setOpen(false);
        });
    } catch (err) {
      console.log('Failure Catched', err);
      enqueueSnackbar('Error in creating release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.WARN,
      });
      setOpen(false);
    }
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log('Changed Value  ', value);
    setPage(value);
    releaseTopicsAPI(value);
  };
  useEffect(() => {
    releaseTopicsAPI();
  }, []);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        <MainContentHeader
          label="KPI (Metrics) Insights"
          buttonHref={parseURI(METRIC_INSIGHT_NEW, projectId,metricId)}
          buttonLabel="Add Insights"
        />
        <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableComponentHeader>
                <TableRow>
                  <TableCell sx={TableHeaderLabel}>
                    Review Date
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <GridMoreVertIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell sx={TableHeaderLabel} title="YYYY/MM/DD">
                    Comments
                  </TableCell>
                  <TableCell sx={TableHeaderLabel} title="YYYY/MM/DD">
                    Expected
                  </TableCell>
                  <TableCell sx={TableHeaderLabel} title="YYYY/MM/DD">
                    Acheived
                  </TableCell>
                  <TableCell sx={TableHeaderLabel}>Actions</TableCell>
                </TableRow>
              </TableComponentHeader>
              <TableComponentBody>
                {isResultNotEmpty(metricInsights) ? (
                  extractResult(metricInsights).map((row: any, index: number) => (
                    <TableRow key={row?._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={TableContentLebel} component="th" scope="row">
                        {ParseToDate(row?.reviewDate)}
                      </TableCell>
                      <TableCell sx={TableContentLebel} component="th" scope="row">
                        {row?.comment}
                      </TableCell>
                      <TableCell sx={TableContentLebel} component="th" scope="row">
                        {row?.expected}
                      </TableCell>
                      <TableCell sx={TableContentLebel} component="th" scope="row">
                        {row?.achieved}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button disabled variant="outlined" href={`release-topics/${row?._id}/edit`}>
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <EmptyResult />
                )}
              </TableComponentBody>
            </Table>
          </TableContainer>
          <Pagination
            count={PageCount(metricInsights?.totalCount)}
            sx={{ padding: 2, marginLeft: '30%' }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
