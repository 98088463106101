import { PaginationConst } from '../utils/constants/AppConstant';
import { PageItemCount } from '../utils/lib/PaginationUtil';
import HttpClient from './HttpClient';
import { RELEASE_SERVICE_HOST, RELEASES_API_PREFIX } from './RestConstants';

/**
 * @description Get release based on the request criteria
 * @param {String} productId
 * @returns {Promise}
 */
export async function getReleaseTopicsPagination(projectId: string, pageNumber: number = 0,pageSize:number = PaginationConst.PAGE_SIZE,  filterObj: Object = {}) {
  console.log(filterObj);
  return HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/releases-topics?skip=${PageItemCount(pageNumber)}&size=${
      pageSize
    }`,
    params: filterObj,
  });
}
export async function getReleaseTopics(projectId: string, filterObj: Object = {}) {
  console.log('getReleaseTopics Triggered');
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/releases-topics?size=0&skip=0`,
    params: filterObj,
  });
  console.log(response);
  return response;
}

/**
 * @description Create new release
 * @param {Object} releaseObj
 * @returns {Promise}
 */
export async function createReleaseTopic(projectId: string, releaseObj: Object = {}) {
  const response = await HttpClient.POST({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/releases-topics`,
    data: releaseObj,
  });

  return response;
}

/**
 * @description Update Release
 * @param {String} productId
 * @param {String} releaseId
 * @param {Object} releaseObj
 * @returns {Promise}
 */
export async function updateRelease(productId: String, releaseId: String, releaseObj: Object = {}) {
  const response = await HttpClient.PATCH({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${productId}/releases/${releaseId}`,
    data: releaseObj,
  });

  return response;
}

export async function patchReleaseTopic(projectId: string, releaseTopicId: String, releaseObj: Object = {}) {
  const response = await HttpClient.PATCH({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/releases-topics/${releaseTopicId}`,
    data: releaseObj,
  });
  return response;
}

export async function getReleaseTopicById(projectId: string, releaseTopicId: String) {
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/releases-topics/${releaseTopicId}`,
  });
  return response;
}

export async function getReleaseTopicLinks(projectId: string, releaseTopicId: String) {
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/releases-topics/${releaseTopicId}/links`,
  });
  return response;
}

export async function updateStatus(projectId: string, releaseTopicId: String, releaseObj: Object = {}) {
  const response = await HttpClient.PATCH({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/releases-topics/${releaseTopicId}/status`,
    data: releaseObj,
  });
  return response;
}

export async function getReleaseTopicStatusInsights(projectId: string) {
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/insights`,
  });
  return response;
}
