import { COLOR_FADE_BLACK_PRIMARY, COLOR_FADE_BLACK_SECONDARY, COLOR_THEME_BLUE_PRIMARY } from '../values/Colors';
import { DEFAULT_FONT_FAMILY } from '../values/FontFamily';
import { SIZE_HIGH_1, SIZE_MUDIUM_2 } from '../values/FontSize';

export const TableHeaderLabel = {
  color: COLOR_FADE_BLACK_PRIMARY,
  fontSize: SIZE_HIGH_1,
  fontWeight: 'bold',
  fontFamily: DEFAULT_FONT_FAMILY,
};
export const TableContentLebel = {
  color: COLOR_FADE_BLACK_SECONDARY,
  fontSize: SIZE_MUDIUM_2,
  fontFamily: DEFAULT_FONT_FAMILY,
};

export const HeaderLabel = {
  color: COLOR_THEME_BLUE_PRIMARY,
  fontSize: 30,
};

export const TableNotFound = {
  color: COLOR_FADE_BLACK_SECONDARY,
  fontSize: SIZE_MUDIUM_2,
  fontFamily: DEFAULT_FONT_FAMILY,
  textAlign:'center'
};
