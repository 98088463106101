export const VALIDATION_PLANS = "/home/dashboard";
export const HOME_DASHBOARD = "/home/dashboard";
export const ROADMAP_HOME = "/{0}/roadmaps";
export const ROADMAP_CREATE = "/{0}/roadmaps/create";
export const ROADMAP_TIMELINE = "/{0}/roadmaps/timelineview";
export const VALIDATION_PLANS_HOME = "/{0}/validation-plans";
export const PRODUCTS_HOME = "/{0}/products";
export const RELEASE_TOPICS_LIST = "/{0}/release-topics";
export const USER_GROUPS = "/{0}/user-groups";
export const NOTIFICATIONS = "/{0}/notifications";
export const CREATE_RELEASE_TOPICS = "/{0}/release-topics/create";
export const GROOMING = "/{0}/release-topics/{1}/groom";
export const COMMIT_HOME = "/products/{0}/release/create";
export const EDIT_RELEASE_TOPIC = "/{0}/release-topics/{1}/edit";
export const MY_ACTIONS = "/{0}/myactions";
export const CREATE_PRODUCT = "/{0}/products/create"; 
export const EDIT_RELEASE = "/{0}/products/{1}/release/{2}/update"; 
export const GROOM_PRODUCT = "/{0}/products/{1}/release/{2}/manage"; 
export const RELEASE_MANIFEST = "/{0}/release-manifest/{1}/releases/{2}"; 
export const CREATE_VALIDATION_PLAN = "/{0}/validation-plans/new";
export const ADD_USER_TO_GROUP = "/{0}/user-groups/{1}/add-user";
export const CREATE_RELEASE = "/{0}/products/{1}/release/create";
export const COMMIT_RELEASE = "/{0}/products/{1}/release/{2}/commit"
export const USERS_LIST = "/{0}/user-groups/{1}/users"
export const RELEASE_DETAILS = "/{0}/release-manifest/{1}/releases/{2}"
export const DASHBOARD = "/{0}/dashboard"
export const PROJECTS = "/projects"
export const CREATE_PROJECTS = "/projects/new"
export const UPDATE_VALIDATION_PLANS = "/{0}/validation-plans/{1}/edit"
export const CREATE_USER_GROUP = "/{0}/user-groups/new";
export const ADD_USER_TO_ACOUNT = "/{0}/users/new";
export const MILE_STONES_LIST = "/{0}/milestones";
export const MILE_STONES_CREATE = "/{0}/milestones/new";
export const UPDATE_USER_PASSWORD = "/{0}/users/{1}/password-reset";
export const PROFILE = "/{0}/profile";
export const ACCOUNT = "/{0}/account";
export const USERS = "/{0}/users";
export const METRICS_LIST = "/{0}/metrics";
export const METRICS_NEW = "/{0}/metrics/new";
export const METRIC_INSIGHT_LIST = "/{0}/metrics/{1}/insights";
export const METRIC_INSIGHT_NEW = "/{0}/metrics/{1}/insights/new";

export function parseURI(uri:string,...args:  (string | undefined)[]):string{
    if(!args || args.length<=0) return uri;
    else{
        args.map((val,index)=>{
            if(val){
                uri = uri.replace(`{${index}}`,val);
              //  console.log(uri,`{${index}}`,val);
            }           
        })
        return uri;
    }
}