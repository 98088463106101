import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {  useParams } from 'react-router-dom';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';

import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { TableContentLebel, TableHeaderLabel } from '../../../styles/fonts/FontStyle';
import { getValidationPlanWithReleaseTopics } from '../../../services/ValidationPlans';
import { ValidationPlanInterface } from '../../../utils/interface/ValidationPlans';
export function ListValidationPlansWithTopics(props = {}): React.ReactElement {
  const [open, setOpen] = React.useState(false);
  const [validationPlansList,setValidationPlansList] = useState<Array<ValidationPlanInterface>>([]);
  const {validationPlanId='',projectId=''} = useParams();
  const releaseTopicsAPI = async () => {
    setOpen(true);
    if(validationPlanId){
      const releaseTopics: any = await getValidationPlanWithReleaseTopics(projectId,validationPlanId);
      setValidationPlansList(releaseTopics.data.result);
    }
    setOpen(false);
  };

  useEffect(() => {
    releaseTopicsAPI();
  }, []);

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        <MainContentHeader label="Release Topics Linked"
         buttonHref={`/release-topics/create`} buttonLabel="Release Topics Linked" />
        <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={TableHeaderLabel} >Release Topic</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {validationPlansList.map((row: any, index: number) => (
                  <TableRow key={row?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={TableContentLebel} component="th" scope="row">
                      {row?.result[0]?.name}
                    </TableCell>                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination count={10} sx={{ padding: 2, marginLeft: '30%' }} />
        </Grid>
      </Grid>
    </Container>
  );
}
