import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Container, CssBaseline, Grid, Paper, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../utils/constants/AppConstant';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { createProduct } from '../../../services/Product';
import React from 'react';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { useParams } from 'react-router-dom';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { UserSelectorDropdown } from '../../shared-components/user-selector-dropdown/UserSelectorDropdown';
export default function CreateProduct() {
  const { projectId = '' } = useParams();
  const [open, setOpen] = React.useState(false);

  const onFormSubmit = async () => {
    console.log(`Rest API Call Save Release`);
    try {
      const result = await createProduct(projectId, formik.values);
      if (result) {
        enqueueSnackbar('Product Created Successfully', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        });
      } else {
        enqueueSnackbar('Error in creating release', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.WARN,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validationSchema = yup.object({
    name: yup.string().required('Please enter name'),
    description: yup.string().required('Please enter description'),
    category: yup.string().required('Please enter category'),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: '',
      description: '',
      category: '',
      productOwner: '',
      productBoardUrl: '',
      productRepoUrl:''
    },
    onSubmit: onFormSubmit,
  });

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        <MainContentHeader label="Product Team" buttonLabel="Create Product Team" />
        <Grid item xs={8} md={8} lg={8}>
         
            {/* 
                List of attributes
                1. Release Topic name
                2. Description
                3. Planned Release date
                4. Priority (high,medium,low)
                5. Reference ()
                6. Release Demo (demo1)
                7. Source (person / document)
                8. Topic Owners (person-1,person-2,person-3)
              */}
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 1, padding: 4, width: '100%' }}>
                <Grid item xs={12} sx={{}}>
                  <TextField
                    required
                    fullWidth
                    id="name"
                    name="name"
                    margin="normal"
                    label="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <TextField
                    fullWidth
                    id="description"
                    name="description"
                    margin="normal"
                    label="Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                  />
                </Grid>
                <Grid item xs={12} sx={{}}>
                  <TextField
                    fullWidth
                    id="category"
                    name="category"
                    margin="normal"
                    label="Category"
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    error={formik.touched.category && Boolean(formik.errors.category)}
                    helperText={formik.touched.category && formik.errors.category}
                  />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: 2 }}>
                  <UserSelectorDropdown
                    callback={(data: any) => {
                      console.log('Data', data);
                      formik.setFieldValue('productOwner', data);
                    }}
                  ></UserSelectorDropdown>
                </Grid>
                <Grid item xs={12} sx={{}}>
                  <TextField
                    fullWidth
                    id="productBoardUrl"
                    name="productBoardUrl"
                    margin="normal"
                    label="Product Board URL"
                    value={formik.values.productBoardUrl}
                    onChange={formik.handleChange}
                    error={formik.touched.productBoardUrl && Boolean(formik.errors.productBoardUrl)}
                    helperText={formik.touched.productBoardUrl && formik.errors.productBoardUrl}
                  />
                </Grid>
                <Grid item xs={12} sx={{}}>
                  <TextField
                    fullWidth
                    id="productRepoUrl"
                    name="productRepoUrl"
                    margin="normal"
                    label="Product Repository URL"
                    value={formik.values.productRepoUrl}
                    onChange={formik.handleChange}
                    error={formik.touched.productRepoUrl && Boolean(formik.errors.productRepoUrl)}
                    helperText={formik.touched.productRepoUrl && formik.errors.productRepoUrl}
                  />
                </Grid>
                <br />
                <Grid container xs={12} sx={{ marginLeft: 6, minWidth: '100%', textAlign: 'center' }}>
                  <Grid item>
                    <center>
                      <Button sx={{ marginX: 2 }} color="primary" variant="contained" type="submit">
                        Create
                      </Button>

                      <Button sx={{ marginX: 2 }} color="inherit" variant="contained" type="reset">
                        Clear
                      </Button>
                    </center>
                  </Grid>
                </Grid>
              </Box>
            </form>
        </Grid>
      </Grid>
    </Container>
  );
}
