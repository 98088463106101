import * as React from 'react';
import Grid from '@mui/material/Grid';

import AddIcon from '@mui/icons-material/Add';
import { useEffect } from 'react';
import {
  Container,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { getUsersByGroup } from '../../../services/Auth';
import { useParams } from 'react-router-dom';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { ADD_USER_TO_GROUP, parseURI } from '../../../utils/parser/URIFormatter';
import { EmptyResult, extractResult, isResultNotEmpty } from '../../shared-components/table/TableComponent';
import { UserGroupsListInterface } from '../../../utils/interface/UserGroupsListInterface';
import { PageCount } from '../../../utils/lib/PaginationUtil';
import { TableHeaderLabel } from '../../../styles/fonts/FontStyle';
export default function ListUsersByGroup() {
  const { projectId = '' } = useParams();
  const [open, setOpen] = React.useState(false);
  const { usergroupid = '' } = useParams();
  const [usergroups, setUserGroups] = React.useState<UserGroupsListInterface>();
  const [page, setPage] = React.useState(1);
  const getUserGroupsListAPI = async (pageNumber: number = 1) => {
    setOpen(true);
    const groupList = await getUsersByGroup(projectId, usergroupid, pageNumber);
    setUserGroups(groupList.data);
    setOpen(false);
  };
  useEffect(() => {
    getUserGroupsListAPI();
  }, []);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log('Changed Value  ', value);
    setPage(value);
    getUserGroupsListAPI(value);
  };

  const populateUsers = (row: any) => {
    if (row && row.users && row.users.length > 0) {
      return (
        <TableRow key={row?._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {row.users[0]?.firstName}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.users[0]?.lastName}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.users[0]?.email}
          </TableCell>
        </TableRow>
      );
    }
  };
  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        <MainContentHeader
          label="Users"
          buttonHref={parseURI(ADD_USER_TO_GROUP, projectId, usergroupid)}
          buttonLabel="Add User"
        />
        <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={TableHeaderLabel}>Firstname</TableCell>
                  <TableCell sx={TableHeaderLabel}>Lasname</TableCell>
                  <TableCell sx={TableHeaderLabel}>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isResultNotEmpty(usergroups) ? (
                  extractResult(usergroups).map((row: any, index: number) => populateUsers(row))
                ) : (
                  <EmptyResult />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={PageCount(usergroups?.totalCount)}
            sx={{ padding: 2, marginLeft: '30%' }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
