import React, { useEffect, useState } from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { getRoadMaps } from '../../../services/RoadMap';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent, { timelineContentClasses } from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Container, Grid, Paper, Box, Typography, Button } from '@mui/material';
import { ParseInstant, ParseToDate } from '../../../utils/parser/DateParser';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { parseURI, ROADMAP_CREATE } from '../../../utils/parser/URIFormatter';
export function ListRoadMapMilestoneView(props = {}): React.ReactElement {
  const { projectId = '' } = useParams();
  const [open, setOpen] = React.useState(false);
  const [roadMaps, setRoadMap] = useState([]);
  const listRoadMaps = async () => {
    const result = await getRoadMaps(projectId);
    setRoadMap(result.result);
    setOpen(false);
  };
  useEffect(() => {
    setOpen(true);
    listRoadMaps();
  }, []);

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        <Grid container spacing={2}>
          <MainContentHeader label="Road Map" buttonHref={parseURI(ROADMAP_CREATE, projectId)} buttonLabel="Create" />
          
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Timeline
              sx={{
                [`& .${timelineContentClasses.root}`]: {},
              }}
            >
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Start</TimelineContent>
              </TimelineItem>
              {roadMaps.map((row: any, index: number) => (
                <TimelineItem>
                  <TimelineOppositeContent>{ParseToDate(row.endDate)}</TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>{row.name}</TimelineContent>
                </TimelineItem>
              ))}
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot />
                </TimelineSeparator>
                <TimelineContent>End</TimelineContent>
              </TimelineItem>
            </Timeline>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
