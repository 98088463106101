
import HttpClient from './HttpClient';
import {  ARTIFICAL_ASSISTANCE_API_PREFIX, ARTIFICAL_ASSISTANCE_SERVICE_HOST } from './RestConstants';


/**
 * @description Create new release
 * @param {Object} releaseObj
 * @returns {Promise}
 */
export async function describeTopic( payload: Object = {}) {
  const response = await HttpClient.POST({
    BASE_URL: ARTIFICAL_ASSISTANCE_SERVICE_HOST,
    END_POINT: `${ARTIFICAL_ASSISTANCE_API_PREFIX}/describe`,
    data: payload,
  });

  return response;
}
