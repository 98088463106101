import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { FilterParams, TYPE_TEXT_FIELD } from './config/FilterParams';
import { Divider, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { useNavigate } from 'react-router-dom';
import { FilterInnerGrid, FilterPane } from './layout/FilterPane';
type Props = {
  params: Array<FilterParams>;
  callback?: any;
  clearFilter?: any;
};
type FilterHolder = {
  [key: string]: string | undefined;
};
export default function Filter(props: Props) {
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState<FilterHolder>({});
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  const handleChange = (field: string, event: any) => {
    const filter: FilterHolder = query;
    filter[field] = event.target.value;
    setQuery(filter);
    console.log('Event ', query);
  };
  const TextFields = (field: FilterParams) => {
    return (
      <>
        <Typography sx={{ marginLeft: 2 }}>{field.label}</Typography>
        <TextField
          onChange={(evt) => {
            handleChange(field.key, evt);
          }}
          sx={{ margin: 2 }}
          id={field.key}
          label={field.label}
          variant="outlined"
        />
      </>
    );
  };

  const DropDown = (field: FilterParams): any => {
    if (field.enums) {
      return (
        <>
          <Typography sx={{ marginLeft: 2 }}>{field.label}</Typography>
          <Select
            labelId="demo-simple-select-label"
            id={field.key}
            // value={age}
            label={field.label}
            sx={{ m: 2 }}
            onChange={(evt) => {
              handleChange(field.key, evt);
            }}
          >
            {field.enums.map((item) => {
              return <MenuItem value={item}>{item.toUpperCase()}</MenuItem>;
            })}
          </Select>
        </>
      );
    } else {
      return <></>;
    }
  };

  const populateField = (field: FilterParams) => {
    return field.type === TYPE_TEXT_FIELD ? TextFields(field) : DropDown(field);
  };

  const buildParams = (query: FilterHolder): string[] => {
    console.log(Object.keys(query));
    // for(){

    // }
    return Object.keys(query);
  };

  const parseQueryPushBack = () => {
    setOpen(false);
    const keys: string[] = buildParams(query);
    let queryString = '';
    for (let item in keys) {
      console.log(`${keys[item]} - ${query[keys[item]]}`);
      queryString += `${keys[item]}=${query[keys[item]]}&`;
    }
    props.callback(`?${queryString}`);
    //navigate(`?asc=false&status=grooming`, { replace: true });
  };
  const clearFilter = () => {
    props.callback('?');
  };

  return (
    <FilterPane>
      <FilterInnerGrid>
        <Button variant="outlined" onClick={handleClickOpen}>
          <FilterAltIcon />
        </Button>
        <Button variant="outlined" onClick={clearFilter}>
          <FilterAltOffIcon />
        </Button>
      </FilterInnerGrid>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Filter</DialogTitle>
        <Divider />
        {props.params.map((fieldElement) => {
          return populateField(fieldElement);
        })}
        <Button sx={{ m: 2 }} variant="contained" onClick={parseQueryPushBack}>
          Submit
        </Button>
        <Button sx={{ m: 2 }} variant="contained" onClick={clearFilter}>
          Clear
        </Button>
      </Dialog>
    </FilterPane>
  );
}
