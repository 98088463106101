import styled from "@emotion/styled";

export const FilterPane = styled('div')({

 });
 
 export const FilterInnerGrid = styled('div')({
    position:'fixed',
    bottom:'15px',
    right:'15px'  ,
    boxShadow:'0px 0px 2px #000',
    padding:'5px'
 });
 