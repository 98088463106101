class GGLCalender{
    
    addToCalender(title:string | undefined,description:string | undefined){
        return this.parseURL(title,description);
    }

    parseURL(title:string | undefined,description:string | undefined):string{
        const GOOGLE_ADD_TO_CALENDER_URL = `https://calendar.google.com/calendar/u/0/r/eventedit?text=${title}&details=${description}`;
        return GOOGLE_ADD_TO_CALENDER_URL;
    }
}

const Calender = new GGLCalender();
export default Calender ;