import { styled } from '@mui/system';
import { HeaderLabel } from '../fonts/FontStyle';



export const IHeaderLabel = styled('p')(HeaderLabel);

export const ITableHeader = ({
  color: '#2c4389',
  fontSize: 30,
});
