import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, Skeleton } from '@mui/material';
import { GridItemName } from '../../releases-topics/details/styles/DetailsStyle';
/**
 *
 * @param props
 * @returns
 */
export default function HeaderCard(props: any) {
  const loader = () => {
    return (
      <CardContent>
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
      </CardContent>
    );
  };
  const content = () => {
    return (
      <CardContent>
        <GridItemName>{props.title || 'Loading'}</GridItemName>
        <Typography variant="body2" color="text.secondary">
          {props.description}
        </Typography>
      </CardContent>
    );
  };
  return <>{props.title == null ? loader() : content()}</>;
}
